<template>
    <div>
      <h3>Navigation</h3>
      <v-list>
        <v-list-item
          title="Home"
          value="home"
          :to="{ name: 'home' }"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi-home"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          title="Upload"
          value="upload"
          :to="{ name: 'upload' }"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi-cloud-upload"></v-icon>
          </template>
        </v-list-item>

        <v-list-item
          v-for="job in jobs"
          :key="job.job_id"
          :title="job.job_name"
          :subtitle="job.date"
          :value="job.job_id"
          :to="{ name: 'job', params: { job_id: job.job_id } }"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi-flask-outline"></v-icon>
          </template>
        </v-list-item>

    </v-list>
  </div>
</template>


<script>

import { mapGetters } from 'vuex';

export default {
  name: 'jobs-list',
  data() {
    return {
      job_list: []
    };
  },
  components: {

  },
  computed: {
    ...mapGetters('jobs',[
        'jobs',
    ]),
  },
  watch: {
    jobs() {
      this.job_list = this.jobs;
      
    }
  },
  mounted() {
    this.$store.dispatch('jobs/retrieveJobsByStatus', 'all')
  }
}
</script>


