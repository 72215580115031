import axios from "axios";

import endpoint from '../../endpoint.js';

const state = {
  token: localStorage.getItem("user-token") || "",
  currentUser: JSON.parse(localStorage.getItem("current-user")) || null,
  status: "",
  hasLoadedOnce: false
};

const mutations = {
  authRequest: state => {
    state.status = "loading";
  },
  authSuccess: (state, user) => { 
    
    state.status = "success";
    state.token = user.accessToken;
    state.hasLoadedOnce = true;

    let currentUser = {
      user_id: user.user_id,
      username: user.username,
      email:    user.email
    };

    state.currentUser = currentUser;
    localStorage.setItem("current-user", JSON.stringify(currentUser));
    localStorage.setItem("user-token", user.accessToken);
  },
  authError: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
    localStorage.removeItem("user-token");
  },
  authLogout: state => {
    state.token = "";
    state.currentUser = "";
    localStorage.removeItem("user-token");
    localStorage.removeItem("current-user");
    console.log("cleared");
  }
};

const actions = {

  retrieveToken (context, credentials ) {
    return new Promise((resolve, reject) =>  {
      context.commit('authRequest');
      axios.post(endpoint + '/api/v1/login', {
        email : credentials.email, 
        password: credentials.password
      })
      .then(response => {
        context.commit('authSuccess', response.data);
        resolve(response)
      })
      .catch(error => {
        context.commit('authError', error);
        reject(error)
      })
    });
  },
  authLogout: (context) => {
    return new Promise((resolve, reject) =>  {
      context.commit('authLogout');
      if(state.token == "") {
        console.log("logged out")
        resolve()
      }
      else {
        reject()
      }
    })
  }
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  currentUser: state => state.currentUser
};

const authModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}

export default authModule;