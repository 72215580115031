import axios from "axios";

import authHeader from '@/services/authHeader';
import endpoint from '@/store/endpoint';

const state = {
  jobs: null,
  error: null,
  job_details: null
};

const mutations = {
  update_jobs: (state, jobs) => { 
    state.jobs = jobs;
  },
  update_error: (state, error) => { 
    state.error = error;
  },
  update_job_details: (state, job) => {
    state.job_details = job
  }
};

const actions = {

    retrieveJobsByStatus (context, status ) {
        return new Promise((resolve, reject) =>  {

            const URL = endpoint + '/api/v1/job/whitefly/status/' + status;

            axios.get(URL, { headers: authHeader() })
            .then(response => {
              if(response.status == 200) {
                context.commit('update_jobs', response.data.jobs);
                resolve(response)
              }
            })
            .catch(function (error) {
                reject(error)
            });

        });
    },
    retrieveJobDetails (context, job_id) {
        return new Promise((resolve, reject) =>  {

          const URL = endpoint + '/api/v1/job/whitefly/' + job_id;

          axios.get(URL, { headers: authHeader() })
          .then(response => {
            if(response.status == 200) {
              context.commit('update_job_details', response.data.job);
              resolve(response)
            }
          })
          .catch(function (error) {
              context.commit('jobs_error', error);
              reject(error)
          });

        });
    },
    retrieveObjectDetails (context, object) {
      return new Promise ((resolve, reject) => {

        let filename = ''
        // tailored to whitefly output; could be refactored in variables
        if(object.type == 'csv') {
          filename = object.object + '_ObjectDetails.csv'
        }
        else if(object.type =='image') {
          filename = object.object + '_Results.png'
        }
        else if(object.type =='global_counts') {
          filename = 'GlobalCounts.csv'
        }
        else if(object.type =='annotations') {
          filename = 'annotations.json'
        }
        else if(object.type =='zip') {
          filename = 'output.zip'
        }

        const URL = endpoint + '/api/v1/job/whitefly/'+object.job_id+'/objects/' + filename

        axios.get(URL, { headers: authHeader(), responseType: 'blob' })
          .then(response => {
            if(response.status == 200) {
              // Download File
              const href = window.URL.createObjectURL(response.data);

              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', filename); //or any other extension
              document.body.appendChild(link);
              link.click();

              document.body.removeChild(link);
              window.URL.revokeObjectURL(href);
            }
          })
          .catch(function (error) {
              reject(error)
        });

      });
    },
    startJob (context, job_id) {
        return new Promise((resolve, reject) =>  {

            const URL = endpoint + '/api/v1/k8s/job/whitefly/' + job_id;

            axios.get(URL, { headers: authHeader() })
            .then(response => {
              if(response.status == 200) {
                console.log(response)
                resolve(response)
              }
            })
            .catch(function (error) {
                console.log(error)
                reject(error)
            });

        });
    }
};

const getters = {
  jobs: state => state.jobs,
  job_details: state => state.job_details
};

const jobsModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}


export default jobsModule;