import Vuex from 'vuex';

import auth from './modules/Auth';
import jobs from './modules/Jobs';

export default new Vuex.Store({
modules: {
	auth,
	jobs
	}
});
