import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/pages/Home.vue";
import UploadFormImages from "@/components/pages/UploadFormImages.vue";
import Login from "@/components/pages/Login.vue"
import Logout from "@/components/pages/Logout.vue"
import Job from "@/components/pages/Job.vue"

import store from '@/store';

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/upload",
    name: "upload",
    component: UploadFormImages,
  },
  {
    path: "/jobs/:job_id",
    name: "job",
    component: Job,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  
  const loggedIn = store.getters['auth/isAuthenticated'];

  if (authRequired && !loggedIn) {
    next('/login');
  } else {

    next();
  }
});

import axios from "axios";
axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  router.push('/logout')
 }
 return error;
});

export default router;